<script setup lang="ts">
import type { Member } from '@respell/database';
import CreateInviteModal from '~/components/modals/CreateInviteModal.vue';
import UpdateMemberModal from '~/components/modals/UpdateMemberModal.vue';
import UpdateWorkspaceModal from '~/components/modals/UpdateWorkspaceModal.vue';

import { subject } from '@casl/ability';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();

const workspaceStore = useWorkspaceStore();
const { workspace, workspaceMembers, invites, isLoading } =
  storeToRefs(workspaceStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

await useAsyncCache('workspaceMembers/' + workspace.value?.id, () =>
  workspaceStore.loadWorkspaceMembers(),
);

await useAsyncCache('invites/' + workspace.value?.id, () =>
  workspaceStore.loadInvites(),
);

const modal = useModal();

const { copy } = useClipboard();
const toast = useToast();

// currentMember is a Member object corresponding to the currently logged-in User
const currentMember: Ref<Member | undefined> = computed(() =>
  workspaceMembers.value.find((member) => member.userId === user.value?.id),
);

// isAdmin is a Boolean object corresponding to if the logged-in User can edit the workspace they are viewing
const isAdmin: Ref<boolean> = computed(
  () => currentMember.value?.role === 'admin',
);

function openUpdateWorkspaceModal() {
  modal.open(UpdateWorkspaceModal, {
    workspace: workspace,
  });
}

function openUpdateMemberModal(member: Member) {
  modal.open(UpdateMemberModal, {
    member: member,
  });
}

function openCreateInviteModal() {
  modal.open(CreateInviteModal, {
    workspace: workspace.value,
    currentMember: currentMember.value,
  });
}

const resendInvitation = async (row) => {
  await workspaceStore.resendInvite(row.id);
  toast.add({
    title: 'Invitation resent successfully.',
    id: 'modal-success',
  });
};

const rows = computed(() => [
  ...workspaceMembers.value.map((member: Member) => ({
    id: member.id,
    userId: member.userId,
    role: member.role,
    fullName: member.user.profile.fullName,
    email: member.user.email,
    isReceivingEmails: member.isReceivingEmails,
  })),
  ...invites.value.map((invite) => ({
    id: invite.id,
    userId: null,
    // workspaceId: member.workspaceId,
    role: invite.role,
    // fullName: member.user.profile.fullName,
    email: invite.email,
    isReceivingEmails: null,
    token: invite.token,
  })),
]);

const baseColumns = [
  {
    key: 'role',
    label: 'Role',
  },
  {
    key: 'fullName',
    label: 'User',
  },
  {
    key: 'email',
    label: 'Email',
  },
];

const adminColumns = computed(() =>
  isAdmin.value
    ? [
        {
          key: 'isReceivingEmails',
          label: 'Receives Emails',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ]
    : [],
);

const columns = computed(() => {
  return [...baseColumns, ...adminColumns.value];
});

const items = (row) => [
  [
    !row.userId
      ? {
          label: 'Resend Invitation',
          icon: 'i-ph-paper-plane-tilt',
          click: () => resendInvitation(row),
        }
      : {
          label: 'Edit',
          icon: 'i-ph-note-pencil',
          click: () => openUpdateMemberModal(row),
        },
    !row.userId
      ? {
          label: 'Delete',
          icon: 'i-ph-trash-simple',
          click: () =>
            workspaceStore.updateInvite(row.id, {
              status: 'revoked',
            }),
        }
      : {
          label: 'Delete',
          icon: 'i-ph-trash-simple',
          disabled: currentMember.value?.id === row.id,
          click: () => workspaceStore.deleteMember(row.id),
        },
  ],
];
</script>
<template>
  <NuxtLayout name="account" :containerless="true">
    <div class="flex justify-between">
      <div class="main-title flex items-center">
        {{ workspace?.name }}
        <UIcon
          v-if="can('edit', subject('Workspace', workspace))"
          name="i-ph-note-pencil"
          class="cursor-pointer text-gray-500"
          @click="openUpdateWorkspaceModal"
        />
      </div>
      <UButton
        v-if="isAdmin"
        type="button"
        size="xl"
        @click="openCreateInviteModal"
        >Invite User
      </UButton>
    </div>
    <p class="heading-md py-l">
      Team Members
      <span class="body-sm body-dimmed">
        ({{ rows.length }} {{ rows.length > 1 ? 'users' : 'user' }})
      </span>
    </p>
    <UTable
      :loading="isLoading"
      :loading-state="{
        icon: 'i-ph-arrows-clockwise',
        label: 'Loading...',
      }"
      :progress="{ color: 'primary', animation: 'carousel' }"
      :rows="rows"
      :columns="columns"
    >
      <template #role-data="{ row }">
        <UBadge
          size="xs"
          :label="row.role.charAt(0).toUpperCase() + row.role.slice(1)"
          :color="row.role === 'admin' ? 'emerald' : 'orange'"
          variant="solid"
        />
      </template>
      <template #email-data="{ row }">
        <span class="font-mono">{{ row.email }}</span>
      </template>
      <template #isReceivingEmails-data="{ row }">
        <UIcon
          :name="
            row.isReceivingEmails
              ? row.isReceivingEmails === true
                ? 'i-ph-check'
                : 'i-ph-x'
              : ''
          "
        />
      </template>
      <template #actions-data="{ row }">
        <div class="flex justify-start">
          <UDropdown :items="items(row)" @click.stop>
            <UButton color="gray" variant="ghost" icon="i-ph-dots-three" />
          </UDropdown>
        </div>
      </template>
    </UTable>
    <!--    TODO: Implement CSV invite-->
    <!--    <p class="dimmed body-sm">-->
    <!--      To invite multiple members, you can-->
    <!--      <span-->
    <!--        class="text-accent hover:cursor-pointer"-->
    <!--        @click="-->
    <!--          () => {-->
    <!--            console.log('You clicked upload a csv.');-->
    <!--          }-->
    <!--        "-->
    <!--      >-->
    <!--        <UIcon name="i-ph-file-arrow-up" />-->
    <!--        upload a CSV.-->
    <!--      </span>-->
    <!--    </p>-->
  </NuxtLayout>
</template>
