<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import type { Member, MemberRole } from '@respell/database';
import { z } from 'zod';
import { useWorkspaceStore } from '~/stores/workspaces';

const modal = useModal();
const toast = useToast();

const schema = z.object({
  isReceivingEmails: z.boolean(),
  role: z.string(),
});

type Schema = z.output<typeof schema>;

const workspaceStore = useWorkspaceStore();
const { updateMember } = workspaceStore;
const { workspaceMembers, workspace } = storeToRefs(workspaceStore);

interface Props {
  member: Member;
}

const { member } = defineProps<Props>();

const state = reactive({
  isReceivingEmails: member.isReceivingEmails,
  role: member.role,
});

await useAsyncCache(`workspaceMembers/${workspace.value?.id}`, () =>
  workspaceStore.loadWorkspaceMembers(),
);

const isOnlyAdmin = computed(() => {
  const adminCount = workspaceMembers.value.filter(
    (member) => member.role === 'admin',
  ).length;
  return adminCount === 1 && member.role === 'admin';
});

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  if (isOnlyAdmin.value && event.data.role !== 'admin') {
    toast.add({
      title: 'Cannot change role',
      description:
        'You are the only admin in this workspace. Please add another admin before changing your role.',
      id: 'modal-failure',
      color: 'red',
    });
    return;
  }

  await updateMember(member.id, event);
  modal.close();

  toast.add({
    title: 'Successfully updated workspace member.',
    id: 'modal-success',
  });
};

const roleOptions: MemberRole[] = ['viewer', 'editor', 'admin'];
</script>
<template>
  <UModal>
    <UForm
      :schema="schema"
      :state="state"
      class="w-full space-y-4"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Update {{ member?.fullName }}</div>
        </template>

        <div class="space-y-4">
          <UFormGroup label="Receives Emails" name="isReceivingEmails">
            <div class="flex justify-start">
              <UCheckbox v-model="state.isReceivingEmails" color="primary" />
            </div>
          </UFormGroup>
          <UFormGroup label="Role" name="role">
            <USelectMenu
              v-model="state.role"
              :options="roleOptions"
              :disabled="isOnlyAdmin"
            />
            <p v-if="isOnlyAdmin" class="text-sm text-red-500 mt-1">
              You are the only admin. Add another admin before changing your
              role.
            </p>
          </UFormGroup>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-2">
            <UButton type="button" size="xl" color="white" @click="modal.close"
              >Cancel
            </UButton>
            <UButton type="submit" size="xl">Update </UButton>
          </div>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
