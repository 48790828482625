<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import type { Member, MemberRole, Workspace } from '@respell/database';
import { z } from 'zod';
import type { InviteInput } from '~/server/types';
import { useWorkspaceStore } from '~/stores/workspaces';

const modal = useModal();
const toast = useToast();

const schema = z.object({
  workspaceId: z.string().min(1),
  email: z.string().email('Invalid email'),
  role: z.string().min(1),
});

type Schema = z.output<typeof schema>;

const { workspace, currentMember } = defineProps<{
  workspace: Workspace;
  currentMember: Member;
}>();

const workspaceStore = useWorkspaceStore();
const { createInvite } = workspaceStore;
const { invites } = storeToRefs(workspaceStore);

await useAsyncCache(`invites/${workspace.id}`, () =>
  workspaceStore.loadInvites(),
);

const state = reactive({
  workspaceId: workspace.id,
  email: '',
  role: 'viewer',
});

const isSubmitting = ref(false);

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  isSubmitting.value = true;

  const {
    data: { workspaceId, email, role },
  } = event;

  const existingInvite = invites.value?.find(
    (invite) => invite.email === email,
  );

  if (existingInvite) {
    await workspaceStore.resendInvite(existingInvite.id);
    modal.close();
    toast.add({
      title: 'Invitation resent successfully.',
      id: 'modal-success',
    });
  } else {
    const invite: InviteInput = {
      email: email,
      workspaceId: workspaceId,
      invitedById: currentMember.userId,
      role: role as MemberRole,
    };

    await createInvite(invite);
    modal.close();
    await refreshNuxtData(`invites/${workspaceStore.workspaceId}`);
    toast.add({
      title: 'Successfully invited workspace member.',
      id: 'modal-success',
    });
  }

  setTimeout(() => {
    isSubmitting.value = false;
  }, 1000);
};

const options: MemberRole[] = ['viewer', 'editor', 'admin'];
</script>
<template>
  <UModal>
    <UForm
      :schema="schema"
      :state="state"
      class="w-full space-y-4"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Invite User to {{ workspace.name }}</div>
        </template>

        <div class="space-y-4">
          <UFormGroup label="Email" name="email" required>
            <UInput
              v-model="state.email"
              placeholder="reese@respell.ai"
              icon="i-ph-envelope"
            />
          </UFormGroup>
          <UFormGroup label="Role" name="role" required>
            <USelectMenu v-model="state.role" :options="options" />
          </UFormGroup>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-2">
            <UButton type="button" size="xl" color="white" @click="modal.close"
              >Cancel
            </UButton>
            <UButton :loading="isSubmitting" type="submit" size="xl"
              >Invite
            </UButton>
          </div>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
