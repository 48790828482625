<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import type { Workspace } from '@respell/database';
import { z } from 'zod';
import { useWorkspaceStore } from '~/stores/workspaces';

const modal = useModal();
const toast = useToast();

const schema = z.object({
  name: z.string().min(1),
});

type Schema = z.output<typeof schema>;

const workspaceStore = useWorkspaceStore();
const { updateWorkspace } = workspaceStore;

interface Props {
  workspace: Workspace;
}

const { workspace } = defineProps<Props>();

const state = reactive({
  name: workspace.name,
});

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  try {
    await updateWorkspace(event.data);

    modal.close();
    toast.add({
      title: 'Successfully updated workspace.',
      id: 'modal-success',
    });
  } catch (error) {
    modal.close();

    toast.add({
      title: 'Failed to update workspace.',
      id: 'modal-failure',
      color: 'rose',
    });
  }
};
</script>
<template>
  <UModal>
    <UForm
      :schema="schema"
      :state="state"
      class="w-full space-y-4"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Update {{ workspace.name }}</div>
        </template>

        <UFormGroup label="Name" name="name">
          <UInput v-model="state.name" placeholder="Reese's Workspace" />
        </UFormGroup>

        <template #footer>
          <div class="flex justify-end space-x-2">
            <UButton type="button" size="xl" color="white" @click="modal.close"
              >Cancel
            </UButton>
            <UButton type="submit" size="xl">Update</UButton>
          </div>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
